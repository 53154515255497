<template>
  <div class="reject-referral-sidebar">
    <div class="reject-referral-sidebar--content">
      <div style="display: flex; align-items: center; padding-top: 15px">
        <CRIcon view-box="0 0 24 24" :width="20" :height="20" color="red">
          alert_outline
        </CRIcon>
        <p style="padding-left: 8px; color: #e10055; margin: 0">
          Reject
          <b>{{ referral.companyName }}</b>
          for {{ reservation.managedId }}?
        </p>
      </div>
      <ClassificationSelector
        id="reject-referral-classification-selector"
        ref="classification-selector"
        v-model="classificationId"
        :classification-type-id="6"
        label="Referral Reject Reason"
        sub-label="Reason"
        style="margin-top: 20px"
        classification-required
      />
      <CRTextArea
        v-model="rejectNotes"
        placeholder="Add rejection notes here."
        :rows="4"
        auto-grow
      />
    </div>
    <div class="cr-sidebar-dialog--button-spacer" />
    <div>
      <CRButton
        id="reject-referral-save-btn"
        :loading="submitting"
        class="reject-referral-sidebar--action-btn"
        color="primary"
        @click="submit"
      >
        Save
      </CRButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { EventBus } from '@/utils/event-bus'
import ClassificationSelector from '@/components/ClassificationSelector.vue'

export default {
  components: {
    ClassificationSelector,
  },
  props: {
    reservation: {
      type: Object,
      required: true,
    },
    referral: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      classificationId: null,
      rejectNotes: null,
      submitting: false,
    }
  },
  async mounted() {},
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    async submit() {
      this.submitting = true
      const selectorComponent = this.$refs['classification-selector']
      if (!selectorComponent.validate()) {
        this.submitting = false
        return
      }
      const payload = {
        reservationId: this.referral.reservationId,
        rejectionNotes: this.rejectNotes,
        referralRejectionReasonClassificationId: this.classificationId,
      }
      try {
        const response = await this.$store.dispatch(
          'reservations/rejectReferralV2',
          payload
        )
        this.$store.dispatch('app/showAlert', {
          type: response.data.successful ? 'success' : 'error',
          message: response.data.successful
            ? 'Referral Rejected'
            : response.data.message,
        })
        this.submitting = false
        EventBus.$emit('refresh-detail')
        EventBus.$emit('refresh-reservation-detail')
        EventBus.$emit('refresh-reservation-payments')
        EventBus.$emit('global-table-view-refresh')
        this.close()
      } catch (e) {
        this.showAlert({
          type: 'error',
          message: 'Error rejecting referral.',
        })
        this.submitting = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.reject-referral-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }
}
</style>
